import React from "react";
import Layout from "../components/Layout";
import styled from "styled-components";
import Seo from "../components/seo";
const Tarifs = () => {
  return (
    <Layout>
      <ContentWrapper>
        <Seo title="Magali Sophrologue Toulon Tarifs" />
        <h2>Tarifs des séances</h2>
        <div className="ContentText">
          <div className="Block">
            <p>Séance individuelle en cabinet</p>
            <ul>
              <li>Adultes : 60 €/la séance 1 heure</li>
              <li>Enfants/Adolescents : 50 €/la séance 1 heure</li>
            </ul>
          </div>
          <div className="Block">
            <p>Séance collective</p>
            <ul>
              <li>15 €/personne à l'unitée </li>
              <li>
                75 €/personne (atelier de 6 séances collectives)
                <br />
                <span>*Soit 12,5 €/la séance</span>
              </li>
            </ul>
          </div>
        </div>
      </ContentWrapper>
    </Layout>
  );
};

const ContentWrapper = styled.div`
  width: 80%;
  margin: 0 auto;
  color: var(--green);

  h2 {
    text-align: center;
    margin: 5rem 0;
  }
  .ContentText {
    display: flex;
    flex-wrap: wrap;
    margin-bottom: 5rem;
    justify-content: space-around;
    p {
      font-size: 1.2rem;
    }

    .Block {
      p {
        text-align: center;
      }
      ul {
        text-align: center;
      }
      span {
        font-size: 0.7rem;
      }
    }
  }
`;

export default Tarifs;
